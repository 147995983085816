@import url("https://fonts.googleapis.com/css?family=Raleway:300&display=swap");
@import url('https://fonts.googleapis.com/css?family=Montserrat:300&display=swap');

/*General*/
html, body {
    overflow-x: hidden;
}

.App {
    height: 100%;
    width: 100%;
    padding: 1px;
    font-family: Raleway, sans-serif;
}

p {
    line-height: 2em;
}

h2, h3, h4 {
    margin: 5px;
    font-family: "Montserrat", serif;
    letter-spacing: .3em;
    font-variant: all-petite-caps;
}

/*
Light/Dark
 */

.darkMode {
    background-color: #15131C;
    color: #EDF4ED;
    transition: background-color 1000ms, color 1000ms;
}

.lightMode {
    background-color: #EDF4ED;
    color: #15131C;
    transition: background-color 1000ms, color 1000ms;
}

a.darkMode {
    color: #EDF4ED;
}

a.lightMode {
    color: #15131C;
}

.ListItem.darkMode {
    border-color: #EDF4ED;
    background-color: #EDF4ED;
    color: #15131C;
}

.ListItem.lightMode {
    border-color: #15131C;
    background-color: #15131C;
    color: #EDF4ED;
}

.LineLeft.lightMode span:after {
    border-bottom: 1px solid #890C1D;
}

.LineLeft.darkMode span:after {
    border-bottom: 1px solid #F7C138;
}

.Link.lightMode:hover {
    color: #890C1D;
}

.Link.darkMode:hover {
    color: #F7C138;
}

/*HEADER*/
.Name-Header {
    font-size: 4em;
    letter-spacing: .3em;
    font-variant: all-petite-caps;
    text-align: center;
    width: 100%;
    height: 40vh;
    margin-top: 30vh;
    margin-bottom: 30vh;

}

.Name-Header-First {
    margin: 0;
    font-size: 1em;
    font-family: "Montserrat", serif;
    letter-spacing: .3em;
    font-variant: all-petite-caps;
}

.Name-Header-Surname {
    letter-spacing: .6em;
    font-size: .3em;
}

/*
Other
 */

.Section {
    padding: 5vh 5vw;
}

.Section-Small {
    padding: 5vh 40px;
}

.Line span {
    display: inline-block;
    position: relative;
}

.Line span:before,
.Line span:after {
    content: "...";
    position: absolute;
    height: 5px;
    border-bottom: 1px solid #F7C138;
    top: .5em;
    width: 20vw;
}

.Line span:before {
    right: 100%;
    margin-right: 15px;
}

.Line span:after {
    left: 100%;
    margin-left: 15px;
}

.LineLeft span {
    display: inline-block;
    position: relative;
    padding-left: 5vw;
}


.LineLeft span:before {
    content: "\00B7\00B7\00B7";
}

.LineLeft span:after {
    content: "";
    position: absolute;
    height: 5px;
    top: .5em;
    width: 100%;
}


.LineLeft span:after {
    left: 100%;
    margin-left: 15px;
}

.Project-Content {
    width: 40vw;
}

.Project-Image {
    width: 100%;
    height: auto;
    padding-top: 5%;
}

.Link {
    background-color: unset;
    cursor: pointer;
}

.Project {
    padding: 20vh 0
}

.sidebar {
    position: fixed;
    left: 0;
    padding: 5px;
    height: 100%;
    width: 30px;
    overflow: hidden;
}

.sidebar-grid {
    height: 100%;
}
